import { Component, OnInit } from '@angular/core';
import { getThisYear } from './../../utils/dates.utils';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  thisYear!: number;

  ngOnInit(): void {
    this.thisYear = getThisYear();
  }
}
