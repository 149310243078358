import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})

export class ContactComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private message: NzMessageService
  ) {}
  options = {
    params: new HttpParams(),
  };
  contactform!: FormGroup;

  ngOnInit(): void {
    this.createContactForm();
  }

  createContactForm(): void {
    this.contactform = this.formBuilder.group({
      first_name: [, [Validators.required]],
      last_name: [, [Validators.required]],
      email: [, [Validators.required, Validators.pattern(Regx.email)]],
      phone: [, [Validators.pattern(Regx.phone)]],
      subject: [, [Validators.required, Validators.maxLength(100)]],
      content: [, [Validators.required, Validators.maxLength(500)]],
    });
  }

  onSubmit() {
    this.sendContactForm(this.contactform.value).subscribe(
      (res) => {
        this.contactform.reset();
        this.message.success(
          'We have recieved yout message, we will come back to you soon!'
        );
      },
      (err) => {
        {
          this.message.error(
            'An error occured while sending the message, please try later.'
          );
        }
      }
    );
  }

  sendContactForm(contactForm: ContactForm): Observable<any> {
    this.options.params = new HttpParams();
    return this.http.post(
      `https://api.staging.dataenhancers.io/v1/contact`,
      contactForm,
      this.options
    );
  }
}

export interface ContactForm {
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
  subject: string;
  content: string;
}

export const Regx = {
  phone: /^\+[1-9]{1}[0-9]{8,14}$/,
  email:
    /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/,
};
