<div class="relative bg-gray-50">


  <main class="lg:relative">
    <div class="mx-auto w-full max-w-7xl pt-16 pb-20 text-center lg:py-24 lg:text-left">
      <div class="px-4 sm:px-48 lg:w-1/2 xl:pr-16">
        <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
          <span class="block xl:inline">Your go-to partner to elevate your</span>
          <span class="block text-blue-700 xl:inline"> B2B solutions</span>
        </h1>
        <p class="mx-auto mt-3 max-w-md text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">We help brands transform their businesses through advanced digital services and strong solutions.</p>
        <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
          <div class="rounded-md shadow">
            <a href="/services"
              class="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-700 px-8 py-3 text-base font-medium text-white hover:bg-blue-800 md:py-4 md:px-10 md:text-lg">Services</a>
          </div>
          <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
            <a href="/about"
              class="flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-blue-700 hover:bg-gray-50 md:py-4 md:px-10 md:text-lg">About Us</a>
          </div>
        </div>
      </div>
    </div>
    <div class="relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
      <img class="absolute inset-0 h-full w-full object-cover" src="https://i.ibb.co/pxBVSZc/banner-bg.png" alt="">
    </div>
  </main>
</div>
