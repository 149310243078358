<div class="relative bg-white py-4 sm:py-4">
  <div class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:items-start lg:gap-24 lg:px-48">
    <div class="relative sm:py-16 lg:py-0">
      <div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
        <div class="absolute inset-y-0 right-1/2 w-full rounded-r-3xl bg-gray-50 lg:right-72"></div>
        <svg class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392"
          fill="none" viewBox="0 0 404 392">
          <defs>
            <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20"
              patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
        </svg>
      </div>
      <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
        <!-- Testimonial card-->
        <div class="relative overflow-hidden rounded-2xl pt-64 pb-10 shadow-xl">
          <img class="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1513759565286-20e9c5fad06b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
            alt="">
          <!--           <div class="absolute inset-0 bg-blue-500 mix-blend-multiply"></div>
          <div class="absolute inset-0 bg-gradient-to-t from-blue-600 via-blue-600 opacity-90"></div> -->
          <div class="relative px-8">
            <blockquote class="mt-8">
              <div class="relative text-lg font-medium text-white md:flex-grow">
                <svg class="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-blue-400"
                  fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                  <path
                    d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p class="relative">Just as time is the most valuable resource we have, data is the most valuable asset for any business.</p>
              </div>

              <footer class="mt-4">
                <p class="text-base font-semibold text-blue-200">Said once an Enhancer.</p>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>

    <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
      <!-- Content area -->
      <div class="pt-12 sm:pt-16 lg:pt-20">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">On a mission to enhance processes</h2>
        <div class="mt-6 space-y-6 text-gray-500">
          <p class="text-lg">We are a software company on a mission to make B2B processes smooth and break corporate
            business lock-in. Our vision is to provide clever tools for clever minds, helping them realize clever things
            and make the world a bottleneck-less avenue !</p>
          <p class="text-base leading-7">We value precision and focus, as well as a balance of
            optimism and realism. We strive to be avant-gardists and game changers, with a clear vision of the future of
            B2B worldwide supply chains.</p>
          <p class="text-base leading-7">Our team is dedicated to creating innovative software solutions that help
            businesses succeed. We are committed to helping you achieve your business goals through the
            power of data.</p>
        </div>
      </div>

      <!-- Stats section -->
      <div class="mt-10">
        <dl class="grid grid-cols-2 gap-x-4 gap-y-8">
          <div class="border-t-2 border-gray-100 pt-6">
            <dt class="text-base font-medium text-gray-500">Founded</dt>
            <dd class="text-3xl font-bold tracking-tight text-gray-900">2019</dd>
          </div>

          <div class="border-t-2 border-gray-100 pt-6">
            <dt class="text-base font-medium text-gray-500">Employees</dt>
            <dd class="text-3xl font-bold tracking-tight text-gray-900">6</dd>
          </div>

          <div class="border-t-2 border-gray-100 pt-6">
            <dt class="text-base font-medium text-gray-500">Beta Users</dt>
            <dd class="text-3xl font-bold tracking-tight text-gray-900">883</dd>
          </div>

          <div class="border-t-2 border-gray-100 pt-6">
            <dt class="text-base font-medium text-gray-500">Satisfied Clients</dt>
            <dd class="text-3xl font-bold tracking-tight text-gray-900">3</dd>
          </div>
        </dl>
        <div class="mt-10">
          <a href="/services" class="text-base font-medium text-blue-600">
            Learn more about how we're changing the BtoB world
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
