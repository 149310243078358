<nz-row nzJustify="center">
  <nz-col nzLg="12" nzSm="20" nzXs="20">
    <div class="large-spacer"></div>
    <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Privacy policy</h1>
    <p nz-typography nzType="secondary">We take the protection of your personal data and the privacy of your information
      very seriously.
      This
      privacy policy
      describes the types of data we collect, how we use it and the steps we take to protect it.
    </p>
    <div class="spacer"></div>
    <h2 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">1. Use of collected data
    </h2>
    <h3 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Medias</h3>
    <p nz-typography>All images used on our site are the property of their respective owners. We use these images to
      enhance the user experience and make our website more visually appealing. We do not claim ownership of these
      images, and we make every effort to obtain the necessary permissions to use them on our site.

      We respect the intellectual property rights of others and we expect our users to do the same. If you believe that
      any images used on our site infringe your copyright, please contact us at <b>contact@dataenhancers.io</b> with the
      details of your claim.</p>
    <h3 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Trade mark</h3>
    <p nz-typography>DataEnhancers, Fluid Partner App, Fluid Client App, Fluid Supply and TaskIt are registered
      trademarks of Data Enhancers OÜ. These
      trademarks are used to identify and distinguish our products and services from those of other companies. They are
      protected by trademark laws in various jurisdictions around the world.

      Using these trademarks without the express permission of Data Enhancers OÜ is strictly prohibited. This includes,
      but is not limited to, using these trademarks in connection with the sale of any goods or services, or using them
      in a manner that is likely to cause confusion among customers or that discredits or dilutes the reputation of the
      trademarks.

      If you are unsure whether a particular use of these trademarks is allowed, please contact us at
      <b>contact@dataenhancers.io</b> for clarification.</p>
    <h3 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Cookies</h3>
    <p nz-typography>If you post a comment or message on our site, we will offer to save your name, email address and
      website in cookies. This is only to ensure an optimal user experience and to avoid entering this information each
      time
      you post new content or comment. These cookies expire after 60 days. By submitting a message, an additional cookie
      will be stored in your browser. This cookie does not contain any personal information, it simply indicates the ID
      of
      the message you just sent. It expires after one day.</p>
    <h3 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl"> Embedded content from other sites</h3>
    <p nz-typography>Articles on this site may include embedded content (e.g. videos, images, articles...). Embedded
      content
      from other
      sites behaves in the same way as if the visitor were on that other site. If you have any questions or concerns
      about the embedded content on our website, please do not hesitate to contact us through the contact information
      provided in the below sections.
    </p>
    <h3 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Statistics and audience measurement</h3>
    <p nz-typography>
      DataEnhancers uses the Google analytics service to track statistics and audience measurements of our site. This
      includes information such as the number of visitors to our website, the pages they visit, and their location.
      Google Analytics uses cookies and other tracking technologies to collect this information. The data collected by
      Google Analytics is used to improve our website and enhance the user experience.

      We want to inform you that your data is collected and processed by Google Analytics as an independent controller
      and we encourage you to consult the privacy policy of Google Analytics. You can also install the Google Analytics
      opt-out browser add-on to prevent your data from being used by Google Analytics.</p>
    <div class="spacer"></div>

    <h2 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">2. Use and transmission of your
      personal data</h2>
    <p nz-typography>DataEnhancers does not transmit any personal data to third parties without the explicit consent of
      the user. Any information collected by DataEnhancers, including but not limited to personal information such as
      names, addresses, and email addresses, will only be used for the purpose of providing our services and improving
      our website. </p>
    <div class="spacer"></div>

    <h2 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">3. The rights you have on your data</h2>
    <p nz-typography>If you have left a message on our site or via <b>contact@dataenhancers.io</b>, you have the right
      to
      request a copy of any personal data that we have collected about you. This includes any information that you have
      provided to us through our website or through other means of communication.

      You also have the right to request that we delete any personal data that we have collected about you. Please note
      that this request does not include data that we are required to retain for administrative, legal, or security
      purposes.

      To request a copy of your personal data or to request the deletion of your personal data, please contact us at
      <b>contact@dataenhancers.io</b> and provide us with sufficient information to verify your identity. We will
      respond to
      your request as soon as possible, and in any case, within the legal time frame.</p>
    <div class="spacer"></div>

    <h2 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">4. Contact Information</h2>
    <p nz-typography>You can contact us at <b>contact@dataenhancers.io</b>, or at <b>enhancers@dataenhancers.io</b> with
      any questions
      or concerns about your
      privacy
      or personal data and we will do our best to address them in a timely and efficient manner. Additionally, you can
      also reach out to us through our website's contact form.

      We take any feedback or concerns about our Privacy Policy and data handling practices seriously and will work to
      resolve any issues as quickly as possible. Thank you for choosing DataEnhancers and for entrusting us with your
      personal information.</p>
    <div class="spacer"></div>

    <h2 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">5. How we protect your data</h2>
    <p nz-typography>Dataenhancers anonymizes the data collected and hosts it at multiple remote sites to ensure the
      security and privacy
      of your information. This means that any personal information collected by DataEnhancers is processed in such a
      way that it can no longer be linked to an identified or identifiable natural person. The anonymized data is then
      stored in multiple secure locations, known as remote sites, to ensure that it is protected against unauthorized
      access, alteration, disclosure, or destruction.

      We use industry-standard security measures to protect the data stored on our remote sites, including firewalls,
      encryption, and regular backups. We also regularly review and update our security procedures to ensure that your
      information is protected at all times.

      We retain the anonymized data for as long as necessary to fulfill the purposes for which it was collected, or as
      required by law. We do not share or sell any anonymized data to third parties without your consent.</p>
    <div class="spacer"></div>

    <h2 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">6. What we do in case of
      data breach</h2>
    <p nz-typography>In the unlikely event of a data breach, we will take immediate action to contain and investigate
      the incident. We will also notify affected individuals and relevant authorities as required by law. If you become
      aware of any suspicious or unauthorized access to your personal information, please contact us immediately at
      <b>privacy@dataenhancers.io</b>.

      We will keep you informed of any developments related to the data breach, including the steps we are taking to
      mitigate the impact, prevent further breaches and protect your personal information.

      In addition, we will perform a thorough review of the incident to determine the cause and take appropriate steps
      to prevent similar breaches in the future.

      It is important to note that in the event of a data breach, we will be transparent with our customers and will
      provide all necessary information to help them understand the situation and take the appropriate measures to
      protect their personal information.</p>
    <div class="spacer"></div>

    <h2 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">7. Fluid Partner App</h2>
    <h3 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Background location
    </h3>
    <p nz-typography>The Fluid Partner application uses background location to notify clients of the agent's arrival and
      departure from the location. This allows the client to know when the agent is on the way and when the job is
      completed. Background location is used only when a job is in progress and only in the area of the job. This
      automates the process of taking over missions and ensures that they run smoothly.

      We understand that privacy is a concern for some users, and therefore, we have made it possible for you to disable
      background tracking in the application settings. This means that you have control over the use of your location
      data and you can choose to turn it off at any time.

      Please note that disabling background location tracking may affect the functionality of the application and may
      cause certain features to become unavailable.</p>
    <h3 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Orders & Requests
    </h3>
    <p nz-typography>When placing an order or request through our website or through Fluid Partner App, you
      consent to share certain personal information with Data Enhancers. This information is necessary for us to process
      your order or request and to provide you with the requested services. This data may include order and request
      metadata, such as the items you have ordered or the details of the service you have requested. <br>We have
      implemented
      appropriate measures to protect against unauthorized access, alteration, disclosure, or destruction of your
      personal data. We also regularly review and update our security procedures to ensure that your information is
      protected at all times. To know more about what data is stored for this purpose, feel free to reach
      <b>dpo@dataenhancers.io</b>.</p>
    <h3 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Shopping and Marketplace
    </h3>
    <p nz-typography>At Fluid, we take the privacy and safety of our users seriously, and we are committed to upholding
      our core values of respect, non-violence, and non-discrimination. As part of this commitment, we have developed a
      comprehensive privacy policy for our shopping and marketplace platform that governs how we collect, store, and use
      your data.

      To ensure that our values are upheld, our moderators actively work to inspect all content posted to our platform,
      including products listed for sale, both during and outside of working hours. If a product does not meet our
      values, the user may be banned from using our platform. We reserve the right to remove any content that we deem
      inappropriate or offensive.

      Prohibited content may include but is not limited to: violence, plagiarism, fraudulent or fake products, and any
      other content that violates our <b>community guidelines and values</b>

      By using our shopping and marketplace platform, you agree to these terms and understand that any violation may
      result in your account being banned from our platform. This means that you will not be able to access our services
      or make purchases on our platform.</p>
  </nz-col>
</nz-row>
