import { RouterModule } from '@angular/router';
import { NgZorroAntdModule } from './../ant.module';
import { FooterComponent } from './layouts/footer/footer.component';
import { HeaderComponent } from './layouts/header/header.component';
import { NgModule } from '@angular/core';
import { registerLocaleData, CommonModule } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';

registerLocaleData(en);

@NgModule({
  declarations: [HeaderComponent, FooterComponent],
  imports: [CommonModule, FormsModule, NgZorroAntdModule, RouterModule],
  exports: [HeaderComponent, FooterComponent, NgZorroAntdModule],
})
export class SharedModule {}
