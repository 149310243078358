import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
  search!: string;
  gridStyle = {
    width: '25%',
    textAlign: 'center',
  };

  constructor() {}

  ngOnInit(): void {}
  
}
