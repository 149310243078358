  /**
   * Returns the current UTC full year value.
   * Used in copyright mentions of the footer
   *
   * @returns {number}
   * @memberof FooterComponent
   */
 export function getThisYear(): number {
   return new Date().getFullYear();
 }
