import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  showDemoModal = false;

  constructor() {}

  ngOnInit(): void {}

  handleShowDemo(): void {
    this.showDemoModal = true;
  }
  handleCloseDemo(): void {
    this.showDemoModal = false;
  }
}
